import React, { Component } from "react";
import axios from "axios";

class Contributor extends Component {
  state = {
    channels: [],
  };
  async componentDidMount() {
    const res = await axios.get(
      "https://pg-app-x8dhdqesl30izkxytg23g9dixfecuq.scalabl.cloud/1/classes/Channel",
      {
        headers: {
          "X-Parse-Application-Id": "4An9OrbtnUvVa6hSrmgxiSJ6m08pXBmpiI63NzYb",
          "X-Parse-REST-API-Key": "eydHZZ7eBqqNR7t9YAdNdaZs7NEuSl8LmFfsJPL9",
        },
        params: {
          where: { visible: true },

          order: "-updatedAt",
        },
      }
    );
    const channels = res.data.results;
    this.setState({ channels });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="container"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div className="row">
            {this.state.channels.map((channel) => (
              <div key={channel.objectId} className="col-4">
                <a
                  href={
                    "channel?objectId=" +
                    channel.objectId +
                    "&author=" +
                    channel.MATCHING_AUTHOR
                  }
                >
                  <img
                    src={channel.icon.url}
                    alt={channel.description}
                    width="80%"
                  />
                </a>
                <p
                  style={{
                    paddingLeft: "25px",
                    paddingBottom: "30px",
                    marginTop: "-35px",
                    textTransform: "uppercase",
                  }}
                >
                  {channel.MATCHING_AUTHOR}
                </p>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contributor;
