import React, { Component } from "react";
import Videos from "./videos";
import Iframe from "react-iframe";
import queryString from "query-string";

class Interest extends Component {
  state = {
    id: "",
    interest: "",
  };

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);
    this.setState({ id });
    if (this.state.id === "news") {
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <h2
            style={{
              padding: "40px 15px 20px 0",
              textTransform: "capitalize",
            }}
          >
            {this.state.id}
          </h2>
        </div>
        <div className="container" style={{ paddingBottom: "30px" }}>
          {(() => {
            // Chart for News
            if (this.state.id === "news") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="352px"
                  url="https://incighttv.com/amcharts/chartnews.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }

            // Chart for Agriculture
            if (this.state.id === "agricultural") {
              const urlstring = `https://incighttv.com/amcharts/chartagriculturals.html`;

              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url={urlstring}
                  title="agriId"
                  frameBorder="0"
                />
              );
            }

            // Chart for Currencies
            if (this.state.id === "currencies") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartcurrencies.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }

            // Chart for Energy
            if (this.state.id === "energy") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartenergy.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }

            // Chart for Equities
            if (this.state.id === "equities") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartequities.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }
            // Chart for Interest Rates
            if (this.state.id === "interest rates") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartinterestnews.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }
            // Chart for Metals
            if (this.state.id === "metals") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartmetals.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }
            // Chart for Metals
            if (this.state.id === "options") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartoptions.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }
            // Chart for Metals
            if (this.state.id === "weather") {
              return (
                <Iframe
                  scrolling="no"
                  width="884px"
                  height="392px"
                  url="https://incighttv.com/amcharts/chartweather.html"
                  title="agriId"
                  frameBorder="0"
                />
              );
            }
          })()}
          <div style={{ paddingTop: "20px" }}>
            <p>
              Copyright © 2020. All&nbsp;
              <a
                style={{ color: "red" }}
                href="https://www.barchartmarketdata.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                market data
              </a>
              &nbsp;is provided by Barchart Solutions.
            </p>
            <p>
              Market data provided is at least 10 minute delayed. Information is
              provided 'as is' and solely for informational purposes, not for
              trading purposes or advice. To see all exchange delays and terms
              of use, please see&nbsp;
              <a
                style={{ color: "red" }}
                href="https://www.barchartmarketdata.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;disclaimer
              </a>
              .
            </p>
            <p style={{ paddingBottom: "80px" }}>
              <a href="https://www.cmegroup.com/">
                <img
                  src="https://incighttv.com/files/images/logo-cme.jpg"
                  alt="CME Group"
                />
              </a>
            </p>
          </div>
        </div>
        <div>
          <Videos interest={this.state.id} limit="30" />
        </div>
      </React.Fragment>
    );
  }
}

export default Interest;
