import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export class VideoModal extends Component {
  state = {
    isOpen: false,
    videoId: this.props.videoId,
  };

  openModal = () => {
    this.setState({ isOpen: true });
    console.log(this.props.videoId);
  };

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId={this.state.videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
        <button
          style={{
            backgroundColor: "#000",
            padding: "0px",
            margin: "0px",
            border: "1px solid #000",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={this.openModal}
        >
          <img
            src={`https://img.youtube.com/vi/${this.state.videoId}/0.jpg`}
            alt={this.state.title}
            width="100%"
          />
        </button>
      </React.Fragment>
    );
  }
}

export default VideoModal;
