import React, { Component } from "react";
import axios from "axios";
import Moment from "react-moment";
import "react-modal-video/scss/modal-video.scss";
import { VideoModalFeatured } from "./videoModalFeatured";
import Iframe from "react-iframe";
import styles from "../index.css";

class Featured extends Component {
  state = {
    videos: [],
  };

  async componentDidMount() {
    // needed for props to catch up before going into database
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });

    const res = await axios.get(
      "https://pg-app-x8dhdqesl30izkxytg23g9dixfecuq.scalabl.cloud/1/classes/Video",
      {
        headers: {
          "X-Parse-Application-Id": "4An9OrbtnUvVa6hSrmgxiSJ6m08pXBmpiI63NzYb",
          "X-Parse-REST-API-Key": "eydHZZ7eBqqNR7t9YAdNdaZs7NEuSl8LmFfsJPL9",
        },
        params: {
          where: { KEYWORDS: "news" },
          limit: 1,
          order: "-updatedAt",
        },
      }
    );
    const videos = res.data.results;
    this.setState({ videos });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.videos.map((video) => (
          <div key={video.objectId} className={styles.imgFeatured}>
            <VideoModalFeatured videoId={video.LINK.substr(31)} />
            <div
              style={{
                marginTop: "-35%",
                marginBottom: "25%",
                marginLeft: "15%",
                marginRight: "auto",
                borderColor: "transparent",
              }}
            >
              <h4>News You Can Use</h4>
              <p>{video.TITLE}</p>
              <p>By: {video.AUTHOR}</p>
              <p>
                Date:
                <Moment format=" YYYY-MM-DD HH:mm a" date={video.updatedAt} />
              </p>
              <p>
                <a href="/interest?id=news">
                  More videos and charts on News &gt;&gt;
                </a>
              </p>
            </div>
          </div>
        ))}
        <div className="container" style={{ paddingBottom: "60px" }}>
          <Iframe
            scrolling="yes"
            width="900px"
            height="350px"
            url="https://incighttv.com/stockticker/rssfeeds-news.html"
            title="agriId"
            frameBorder="#fff 2px solid"
            styles={{ paddingLeft: "15px", marginTop: "115px" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Featured;
