import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-sm none container">
      <Link className="navbar-brand" style={{ paddingTop: "20px" }} to="/">
        <img
          alt="InCightTV"
          height="50px"
          src="https://incighttv.com/files/images/incighttv-logo-2.png"
        />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse navbar-left justify-content-end"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav justify-content-end">
          <li className="nav-item">
            <a className="nav-link" href="https://about.incighttv.com">
              ABOUT
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://about.incighttv.com/terms-and-conditions-of-use/"
            >
              TERMS OF USE
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://about.incighttv.com/privacy-policy-2/"
            >
              PRIVACY POLICY
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://getincight.com/">
              GET THE APP
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
