import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import StockTicker from "./components/stockTicker";
import NavBar from "./components/navBar";
import ChannelList from "./components/channelList";
import Channel from "./components/channel";
import NotFound from "./components/notFound";
import IncightHome from "./components/incightHome";
import InternalTest from "./components/internaltest";
import Contributor from "./components/contributor";
import Interest from "./components/interest";
import "./App.css";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <ChannelList />
        <StockTicker />
        <Switch>
          <Route path="/dashboard" component={IncightHome} />
          <Route path="/internaltest" component={InternalTest} />
          <Route path="/channel" component={Channel} />
          <Route path="/contributor" component={Contributor} />
          <Route path="/interest" component={Interest} />
          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="/dashboard" />
          <Redirect to="/not-found" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
