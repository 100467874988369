import React, { Component } from "react";
import axios from "axios";
import Moment from "react-moment";
import "react-modal-video/scss/modal-video.scss";
import { VideoModal } from "./videoModal";
import "../index.css";

class VideoAuthorList extends Component {
  state = {
    keywords: this.props.interest,
    videos: [],
    limit: this.props.limit,
  };

  async componentDidMount() {
    // needed for props to catch up before going into database
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });

    const res = await axios.get(
      "https://pg-app-x8dhdqesl30izkxytg23g9dixfecuq.scalabl.cloud/1/classes/Video",
      {
        headers: {
          "X-Parse-Application-Id": "4An9OrbtnUvVa6hSrmgxiSJ6m08pXBmpiI63NzYb",
          "X-Parse-REST-API-Key": "eydHZZ7eBqqNR7t9YAdNdaZs7NEuSl8LmFfsJPL9",
        },
        params: {
          where: { AUTHOR: this.props.interest },
          limit: this.state.limit,
          order: "-updatedAt",
        },
      }
    );
    const videos = res.data.results;
    this.setState({ videos });
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            {this.state.videos.map((video) => (
              <div key={video.objectId} className="col-md-4">
                <VideoModal videoId={video.LINK.substr(31)} />

                <h4 className="title" style={{ marginBottom: 0 }}>
                  {video.TITLE}
                </h4>
                <p>By: {video.AUTHOR}</p>
                <p>
                  Date:
                  <Moment format=" YYYY-MM-DD HH:mm a" date={video.updatedAt} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoAuthorList;
