import React from "react";
import Iframe from "react-iframe";

const StockTicker = () => {
  return (
    <React.Fragment>
      <Iframe
        scrolling="no"
        width="100%"
        height="35px"
        url="https://incighttv.com/stockticker/index-stock.html"
        title="stockticketid"
        frameBorder="0"
        styles={{ margin: 0, padding: 0, borderWidth: "0" }}
      />
    </React.Fragment>
  );
};

export default StockTicker;
