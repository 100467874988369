import React, { Component } from "react";
import Videos from "./videosbyauthor";
import axios from "axios";
import queryString from "query-string";
import Iframe from "react-iframe";

class Channel extends Component {
  state = {
    achannel: [],
    objectId: "",
    author: "",
    title: "",
  };

  async componentDidMount() {
    const { objectId, author, title } = queryString.parse(
      this.props.location.search
    );
    this.setState({ author });
    this.setState({ objectId });
    this.setState({ title });

    // needed for props to catch up before going into database
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });

    const res = await axios.get(
      "https://pg-app-x8dhdqesl30izkxytg23g9dixfecuq.scalabl.cloud/1/classes/Channel",
      {
        headers: {
          "X-Parse-Application-Id": "4An9OrbtnUvVa6hSrmgxiSJ6m08pXBmpiI63NzYb",
          "X-Parse-REST-API-Key": "eydHZZ7eBqqNR7t9YAdNdaZs7NEuSl8LmFfsJPL9",
        },
        params: {
          where: { objectId: this.state.objectId },
          limit: 1,
          order: "-updatedAt",
        },
      }
    );
    const achannel = res.data.results;
    this.setState({ achannel });
  }

  render() {
    const capInterest =
      this.state.author.charAt(0).toUpperCase() + this.state.author.slice(1);

    return (
      <React.Fragment>
        <div className="container" style={{ padding: "50px 0 " }}>
          {this.state.achannel.map((achannels) => (
            <div
              key={achannels.objectId}
              className="row"
              style={{ paddingTop: "30px" }}
            >
              <div
                className="col-sm-3"
                style={{ float: "left", paddingLeft: "50px" }}
              >
                <img
                  src={achannels.icon.url}
                  alt={achannels.description}
                  width="100%"
                />
              </div>
              <div className="col-sm-7" style={{ float: "right" }}>
                <p>
                  <h1>{achannels.title}</h1>
                  {achannels.description}
                  <br />
                  <button
                    className="btn btn-secondary btn-md"
                    style={{ margin: "20px 0" }}
                  >
                    <a
                      style={{ color: "#fff" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={achannels.webSiteURL}
                    >
                      Visit us at {achannels.webSiteURL}
                    </a>
                  </button>
                </p>
              </div>
            </div>
          ))}
        </div>
        {(() => {
          // Specifically for TastyTrade
          if (this.state.author === "tastytrade") {
            return (
              <div
                className="container"
                style={{
                  paddingBottom: "30px",
                  paddingLeft: "170px",
                }}
              >
                <h3>Taste Trade Livestream</h3>
                <Iframe
                  scrolling="no"
                  width="600px"
                  height="400px"
                  url="https://incighttv.com/amcharts/test2.html"
                  title="agriId"
                  frameBorder="0"
                  style={{ margin: "0 auto" }}
                />
              </div>
            );
          }
        })()}

        <div className="container" style={{ paddingBottom: "30px" }}>
          <h3 style={{ paddingLeft: "15px" }}>
            More Videos from {capInterest}
          </h3>
        </div>
        <div className="container" style={{ paddingBottom: "30px" }}>
          <Videos interest={this.state.author} limit="30" />
        </div>
      </React.Fragment>
    );
  }
}

export default Channel;
