import React, { Component } from "react";
import axios from "axios";

class ChannelList extends Component {
  state = {
    channels: [],
  };
  async componentDidMount() {
    const res = await axios.get(
      "https://pg-app-x8dhdqesl30izkxytg23g9dixfecuq.scalabl.cloud/1/classes/Channel",
      {
        headers: {
          "X-Parse-Application-Id": "4An9OrbtnUvVa6hSrmgxiSJ6m08pXBmpiI63NzYb",
          "X-Parse-REST-API-Key": "eydHZZ7eBqqNR7t9YAdNdaZs7NEuSl8LmFfsJPL9",
        },
        params: {
          where: { visible: true },
          limit: 7,
          order: "-updatedAt",
        },
      }
    );
    const channels = res.data.results;
    this.setState({ channels });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="container"
          style={{ paddingBottom: "30px", paddingTop: "20px" }}
        >
          <p style={{ paddingLeft: "8px" }}>
            <strong>TOP CONTRIBUTORS</strong>
          </p>
          <div className="row">
            {this.state.channels.map((channel) => (
              <div key={channel.objectId} className="col">
                <a
                  href={
                    "channel?objectId=" +
                    channel.objectId +
                    "&author=" +
                    channel.MATCHING_AUTHOR +
                    "&title=" +
                    channel.title
                  }
                >
                  <img
                    src={channel.icon.url}
                    alt={channel.description}
                    width="100%"
                  />
                </a>
              </div>
            ))}
          </div>
          <p>
            <a href="/contributor">See more contributors &gt;&gt;</a>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default ChannelList;
