import React from "react";

const SecondaryNav = () => {
  return (
    <React.Fragment>
      <div class="nav2 col-xs-12" style={{ marginTop: "10px" }}>
        <label style={{ color: "#fff" }}>VIEW CHANNELS:</label>
        <a
          href="/interest?id=news"
          class="text-white btn btn-link"
          type="button"
        >
          NEWS
        </a>
        <a
          href="/interest?id=agricultural"
          class="text-white btn btn-link"
          type="button"
        >
          AGRICULTURAL
        </a>
        <a
          href="/interest?id=currencies"
          class="text-white btn btn-link"
          type="button"
        >
          CURRENCIES
        </a>
        <a
          href="/interest?id=energy"
          class="text-white btn btn-link"
          type="button"
        >
          ENERGY
        </a>
        <a
          href="/interest?id=equities"
          class="text-white btn btn-link"
          type="button"
        >
          EQUITIES
        </a>
        <a
          href="/interest?id=interest%20rates"
          class="text-white btn btn-link"
          type="button"
        >
          INTEREST RATES
        </a>
        <a
          href="/interest?id=metals"
          class="text-white btn btn-link"
          type="button"
        >
          METALS
        </a>
        <a
          href="/interest?id=options"
          class="text-white btn btn-link"
          type="button"
        >
          OPTIONS
        </a>
        <a
          href="/interest?id=weather"
          class="text-white btn btn-link"
          type="button"
        >
          WEATHER
        </a>
      </div>
    </React.Fragment>
  );
};

export default SecondaryNav;
