import React, { Component } from "react";
import Videos from "./videos";

class IncightHome extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Internal Test</h1>
        <p>This is the dashboard</p>
        <Videos interest="agriculture" limit="1" />

        <Videos interest="agricultural" limit="3" />
      </React.Fragment>
    );
  }
}

export default IncightHome;
