import React, { Component } from "react";
import Featured from "./featuredList";
import InterestList from "./interestList";
import SecondaryNav from "./secondaryNav";

class IncightHome extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <SecondaryNav />
        <Featured />
        <InterestList interest="agricultural" />
        <InterestList interest="currencies" />
        <InterestList interest="energy" />
        <InterestList interest="equities" />
        <InterestList interest="interest rates" />
        <InterestList interest="metals" />
        <InterestList interest="options" />
        <InterestList interest="weather" />
      </React.Fragment>
    );
  }
}

export default IncightHome;
