import React, { Component } from "react";
import Videos from "./videos";
import Iframe from "react-iframe";

// A component that is suppose to replace all the *List.jsx files after the fact

class InterestList extends Component {
  state = {
    interest: this.props.interest,
  };

  async componentDidMount() {
    // needed for props to catch up before going into database
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  }

  render() {
    const stockurlstring = `https://incighttv.com/stockticker/rssfeeds-${this.props.interest.replace(
      /\s/g,
      ""
    )}.html`;
    const urlstring = `/interest?id=${this.props.interest}`;
    const idstring = this.props.interest + "id";
    const capInterest =
      this.props.interest.charAt(0).toUpperCase() +
      this.props.interest.slice(1);

    return (
      <React.Fragment>
        <div
          className="container"
          style={{ paddingBottom: "30px", paddingTop: "30px" }}
        >
          <h3
            style={{
              paddingLeft: "15px",
              paddingBottom: "15px",
              textTransform: "capitalize",
            }}
          >
            {this.props.interest}
          </h3>

          <Videos interest={this.props.interest} limit="3" />

          <a style={{ paddingLeft: "15px" }} href={urlstring}>
            More videos and charts on {capInterest} &gt;&gt;
          </a>
        </div>
        <div className="container" style={{ paddingBottom: "60px" }}>
          <Iframe
            scrolling="yes"
            width="900px"
            height="350px"
            url={stockurlstring}
            title={idstring}
            border="#fff 1px solid"
            styles={{ paddingLeft: "15px", marginTop: "115px" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default InterestList;
